import $ from 'jquery';

export const INHERIT = 'inherit';
export const HIDDEN = 'hidden';

const methods = {
  /**
   * Change window overflow style
   *
   * @param {String} val
   */
  setWindowScroll(val) {
    document.getElementsByTagName('html')[0].style.overflow = val;
  },

  getScrollParent(node) {
    return methods.getScrollParentX(node);
  },

  /**
   * Find a parent node which is horizontally scrollable
   */
  getScrollParentX(node) {
    if (!node || !(node instanceof HTMLElement)) {
      return null;
    }
    const styles = window.getComputedStyle(node);
    if (
      styles.overflowX !== 'visible' &&
      styles.overflowX !== 'hidden' &&
      styles.overflowX !== 'clip' &&
      node.scrollWidth >= node.clientWidth
    ) {
      return node;
    }

    // keep searching up the dom
    return methods.getScrollParentX(node.parentNode);
  },

  /**
   * Find a parent node which is vertically scrollable
   */
  getScrollParentY(node) {
    if (!node || !(node instanceof HTMLElement)) {
      return null;
    }
    const styles = window.getComputedStyle(node);
    if (
      styles.overflowY !== 'visible' &&
      styles.overflowY !== 'hidden' &&
      styles.overflowY !== 'clip' &&
      node.scrollHeight >= node.clientHeight
    ) {
      return node;
    }

    // keep searching up the dom
    return methods.getScrollParentY(node.parentNode);
  },

  /**
   * Calculate the positioning of the confirm changes panel based on the given
   * scroll position.
   *
   * @param {Element} scrollingEl
   * @param {Element} table
   * @param {Number} scrollPosition
   *
   * @return {String} position of panel, left or right
   */
  calcConfirmChangesPos(scrollingEl, table, scrollPosition) {
    if (!scrollingEl) {
      return undefined;
    }

    if (!table) {
      // try to find it
      table = $(scrollingEl).find('table');
      if (!(table && table.length > 0)) {
        return undefined;
      }
      [table] = table;
    }

    const scrollLeft = scrollPosition !== 0 ? scrollPosition : scrollingEl.scrollLeft;

    // calculate position from the right side of row
    const rightPos = table.clientWidth - scrollingEl.clientWidth - scrollLeft;

    if (scrollLeft < 200 || rightPos < 100) {
      if (rightPos < 100) {
        // when scrolled mostly to the right, use default position within the actions cell
        return 'none';
      }

      return 'right';
    }

    return 'left';
  },
};

export default methods;
