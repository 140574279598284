import Vue from 'vue';

import VueResize from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import VueForceNextTick from 'vue-force-next-tick';
import AsyncComputed from 'vue-async-computed';

Vue.use(VueForceNextTick);
Vue.use(VueResize);
Vue.use(AsyncComputed, {
  useRawError: true,
  // Custom error handler to properly display the error and exception stack in
  // the console. The default handler prints the stack array directly which
  // isn't properly formatted.
  errorHandler(err /* , vm, stack */) {
    console.error('Caught asyncComputed error\n', err);
  },
});
