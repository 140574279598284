<template>
  <b-modal
    :hide-footer="hideFooter"
    :header="showHeader"
    :enable-click-out-side="enableClickOutside"
    :header-text="headerText"
    :hide-close-button="hideCloseButton"
    :width="width"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="notification-container">
          <div class="notification-header">
            {{ notificationMsg }}
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button :size="3" :label="cancelLabel" class="mr-20 cancel-btn" @click="onClickCancel" />
        <k-button
          v-if="!hideConfirmBtn"
          :size="3"
          :label="confirmLabel"
          class="mr-20"
          @click="onClickConfirm"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'NotificationModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ './b-modal.vue'),
  },
  props: {
    width: {
      type: String,
      required: false,
      default: '400px',
    },
    hideConfirmBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableClickOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerText: {
      type: String,
      required: false,
      default: '',
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    notificationMsg: {
      type: String,
      required: false,
      default: '',
    },
    confirmLabel: {
      type: String,
      required: false,
      default: '',
    },
    cancelLabel: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('on-cancel', ev);
    },
    onClickConfirm(ev) {
      this.$emit('on-confirm', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-container {
  overflow-x: hidden;
}
::v-deep .modal-header.row.end-xs {
  display: none;
}
.notification {
  &-header {
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
    font-size: 16px;
    justify-content: space-between;
  }
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
</style>
