import $ from 'jquery';
import S from 'underscore.string';

import { isBlank } from 'adready-api/helpers/common';

const props = {
  // Label and placeholder props are synonyms
  // We use whichever one we are given, preferring label.
  label: {
    required: false,
    type: String,
    default: null,
  },
  placeholder: {
    required: false,
    type: String,
    default: null,
  },
  tabindex: {
    required: false,
    type: Number,
    default: 0,
  },
};

const computed = {
  labelText() {
    let { label } = this;
    if (!isBlank(label)) {
      // noop for eslint
    } else if (!isBlank(this.placeholder)) {
      label = this.placeholder;
    } else if (isBlank(this.name)) {
      return ''; // at this point give up
    } else {
      // Have a name, humanize it
      // FooBar -> Foo Bar
      label = S(this.name)
        .humanize()
        .words()
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(' ');
    }
    if (this.$options.name === 'KSelect' && this.showRequired) {
      // append asterisk when needed, only for KSelect
      // text inputs already add it via CSS
      label += ' *';
    }
    return label;
  },
};

const methods = {
  /**
   * Helper method for retaining focus in the parent <td>
   *
   * Only if nothing else has received focus and element is not in a modal.
   * i.e., if you click to another element, we should not try to move the focus.
   */
  retainFocus(isDate) {
    this.$forceNextTick(() => {
      const el = $(this.$el);
      if (
        $(':focus').length === 0 &&
        el.parents('modal-wrapper').length === 0 &&
        el.parents('popup').length === 0
      ) {
        const td = el.parents('td').first();
        if (td.length > 0) {
          if (isDate) {
            // workaround to allow retaining focus directly on the input
            // but avoid triggering the datepicker again.
            td.attr('data-disable-focus', 1);
            $(this.$refs.input).focus();
          } else {
            td.attr('tabindex', 0)
              .focus()
              .attr('tabindex', -1);
          }
        }
      }
    });
  },
};

export default {
  props,
  computed,
  methods,
};
