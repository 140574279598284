<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <hoverable #on="{}" :delay="50" :out-delay="250" :stop="true" @hover="onHover">
    <span>
      <slot name="content"></slot>
      <span v-if="haveTooltip && showTooltip" ref="tooltip" role="tooltip" data-tool-tip>
        <span ref="text"
          ><slot>{{ tooltip }}</slot></span
        >
        <div data-popper-arrow></div>
      </span>
    </span>
  </hoverable>
</template>

<script>
import $ from 'jquery';
import { createPopper } from '@popperjs/core';

import { isBlank } from 'adready-api/helpers/common';
import tooltipMixin from '../mixins/tooltip-mixin';

export default {
  name: 'KTooltip',

  mixins: [tooltipMixin],

  data() {
    return {
      popper: null,
      showTooltip: false,
      observer: null,
    };
  },

  computed: {
    haveTooltip() {
      return !isBlank(this.tooltip) || !!this.$slots.default;
    },
  },

  watch: {
    tooltip(val) {
      // when text changes, recompute
      if (this.popper) {
        this.popper.update();
        if (isBlank(val)) {
          this.startObserver();
        } else {
          this.stopObserver();
        }
      }
    },
  },

  mounted() {
    this.observer = new MutationObserver(() => {
      if (this.popper) {
        this.popper.update();
      }
    });
  },

  destroyed() {
    if (this.observer) {
      this.stopObserver();
    }
  },

  methods: {
    startObserver() {
      if (!this.$refs.text) {
        return;
      }
      this.observer.observe(this.$refs.text, {
        // all changes
        subtree: true,
        childList: true,
        characterData: true,
        attributes: true,
      });
    },

    stopObserver() {
      this.observer.disconnect();
    },

    onHover(val) {
      if (val && !this.popper) {
        this.showTooltip = true;
        this.$nextTick(() => {
          if (!(this.haveTooltip && this.showTooltip)) {
            // no tooltip to show, skip
            return;
          }
          // Popper is attached to whatever is provided in the content slot
          this.popper = createPopper($(this.$refs.tooltip).prev()[0], this.$refs.tooltip, {
            placement: this.placement,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, this.offset],
                },
              },
            ],
          });
          if (this.observer && isBlank(this.tooltip) && !!this.$slots.default) {
            // start watching for changes
            this.startObserver();
          }
        });
      }

      if (!val && this.showTooltip) {
        if (this.popper) {
          this.popper.destroy();
          this.popper = null;
        }
        this.showTooltip = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[data-tool-tip] {
  z-index: 999;
  padding: 4px 8px;
  margin: 0;
  color: white;
  background: #333;
  border-radius: 4px;

  &:hover {
    cursor: auto;
  }

  [data-popper-arrow],
  [data-popper-arrow]::before {
    position: absolute;
    z-index: -1;
    width: 8px;
    height: 8px;
  }

  [data-popper-arrow]::before {
    left: 0;
    content: '';
    background: #333;
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: -4px;
  }

  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -4px;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -4px;
  }

  ::v-deep a,
  ::v-deep a:visited,
  ::v-deep a:hover {
    color: $dr_blue;
  }
}
</style>
