export default {
  primaryColor: '#006987',
  primaryGreen: '#00b3aa',
  primaryRed: '#de284a',
  buttonText: 'dark',
  primaryAlt: '#00b3aa',
  primaryTitle: '#5e6577',
  primaryDark: '#212325',
  primaryDark2: '#536176',
  primaryLight: '#9fabb8',
};
