const props = {
  tooltip: {
    required: false,
    type: String,
    default: null,
  },
  placement: {
    required: false,
    type: String,
    default: 'top',
  },
  offset: {
    required: false,
    type: Number,
    default: 8,
  },
};

export default {
  props,
};
