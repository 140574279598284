<template>
  <div id="app">
    <router-view v-if="!adBlock" />
    <notification-modal
      v-if="adBlock"
      class="ad-blk"
      :hide-footer="true"
      header-text="Ad Blocker Detected"
      :notification-msg="adBlock"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import { isDemoInstance } from '~/util/utility-functions';
import { isPopupBlocked } from './util/popup-block-utils';
import NotificationModal from './components/elements/notification-modal.vue';
import config from './config';
import {
  KEY_ACCOUNT_ID,
  KEY_ADVERTISER_ID,
  KEY_DEMO_ACCOUNT_ID,
  KEY_DEMO_ADVERTISER_ID,
  DEMO_ADVERTISERS,
} from '~/constant';

export default {
  name: 'AppRoot',
  components: {
    NotificationModal,
  },
  data() {
    return {
      AD_BLOCKER_TEXT:
        'Your pop-up blocker is preventing our platform from functioning fully. Please turn it off or whitelist this domain, then refresh the page.',
      adBlock: false,
    };
  },
  computed: {
    currentAccountId: get('common/currentAccountId'),
    advertiser: get('common/advertiser'),
  },
  mounted() {
    this.addHandler();
    if (config.PRIMARY_COLOR) {
      const root = document.querySelector(':root');
      root.style.setProperty('--primaryColor', config.PRIMARY_COLOR);
    }
    if (config.BUTTON_TEXT) {
      const root = document.querySelector(':root');
      root.style.setProperty('--overtext', config.BUTTON_TEXT === 'light' ? '#ffffff' : '#303335');
    }
  },
  destroyed() {
    this.removeHandler();
  },
  beforeMount() {
    if (!config.adBlockerCheckEnabled()) {
      this.adBlock = false;
      return;
    }
    axios
      .get('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
      .then(() => {
        this.adBlock = isPopupBlocked() ? this.AD_BLOCKER_TEXT : '';
      })
      .catch((e) => {
        console.error(e);
        this.adBlock = this.AD_BLOCKER_TEXT;
      });
  },
  methods: {
    addHandler() {
      window.addEventListener('message', this.messageEventListener);
    },
    removeHandler() {
      window.removeEventListener('message', this.messageEventListener);
    },
    messageEventListener(event) {
      if (event.origin !== config.FLIP_URL) {
        return;
      }
      const { data } = event;

      if (data.type === 'embedAdvertiserSwitch') {
        const { accountId, id } = data.advertiser;

        if (isDemoInstance()) {
          localStorage.setItem(KEY_DEMO_ACCOUNT_ID, accountId);
          localStorage.setItem(KEY_DEMO_ADVERTISER_ID, id);
          // find the mapped advertiser
          const keys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));
          const demoAdvertiserMapping = keys.includes(id)
            ? DEMO_ADVERTISERS[id]
            : DEMO_ADVERTISERS[0];
          localStorage.setItem(KEY_ACCOUNT_ID, demoAdvertiserMapping.accountId);
          localStorage.setItem(KEY_ADVERTISER_ID, demoAdvertiserMapping.advertiserId);
        } else {
          if (!isBlank(accountId)) localStorage.setItem(KEY_ACCOUNT_ID, accountId);
          if (!isBlank(id)) localStorage.setItem(KEY_ADVERTISER_ID, id);
        }

        localStorage?.setItem('ChangeAdvertiser', true);

        window.location.reload();
      } else if (data.type === 'embedLogout') {
        window.location = data.url;
        window.location.reload();
      }
    },
  },
};
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900';

/* @import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap'; */
</style>

<style lang="scss">
@import './assets/css/app.scss';
</style>
<style lang="css">
@import './../node_modules/c3/c3.min.css';
</style>

<style lang="scss" scoped>
::v-deep .ad-blk .notificationContainer .modal-container .header-txt {
  color: #000 !important;
}
</style>
