import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import { isBlank } from 'adready-api/helpers/common';

export function initSentry(dsn, environment) {
  if (!dsn || isBlank(dsn)) {
    return;
  }

  Sentry.init({
    Vue,
    dsn,
    environment,

    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    logErrors: true,
  });
  Vue.use((vue) => {
    vue.$sentry = Sentry;
  });
  window.$sentry = Sentry;
}
