import Vue from 'vue';

// font-awesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

// loads the actual icons for each style
import './fonts-light';
import './fonts-regular';
import './fonts-solid';
import './fonts-duotone';
import './fonts-brands';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
