const props = {
  autofocus: {
    required: false,
    type: Boolean,
    default: false,
  },
};

function mounted() {
  if (this.autofocus) {
    this.$nextTick(() => {
      this.focus();
    });
  }
}

const methods = {
  /**
   * Focus the input
   */
  focus() {
    if (this.$refs.input) {
      this.$refs.input.focus();
    }
  },
};

export default {
  props,
  mounted,
  methods,
};
