import Vue from 'vue';
import Vuex from 'vuex';
import VuexReset from '@ianwalter/vuex-reset';
import pathify from './store-pathify';
import common from './store/common';
import dashboard from './store/dashboard.store';

Vue.use(Vuex);

// if (process.env.NODE_ENV !== 'production') {
//   pathify.debug();
// }

export default new Vuex.Store({
  modules: {
    common, // common data
    dashboard, // dashboard data
  },
  plugins: [pathify.plugin, VuexReset()],
  strict: process.env.NODE_ENV !== 'production',
});
