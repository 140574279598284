import moment from 'moment';
import { isBlank } from 'adready-api/helpers/common';
import { DATE_FORMAT_MM_DD_YY } from '~/constant';

export function formatDateForAPI(date = new Date()) {
  return moment(new Date(date)).format('yyyy-MM-DD');
}

export function formatDate(date, dateFormat = 'yyyy-MM-DD') {
  return !isBlank(date) ? moment(new Date(date)).format(dateFormat) : '';
}

export function formatDateInEST(date, dateFormat = DATE_FORMAT_MM_DD_YY) {
  if (date !== undefined && date !== null) {
    const d = new Date(date);
    const offSet = d.getTimezoneOffset() / 60;
    return moment(new Date(date))
      .add(offSet, 'hours')
      .format(dateFormat);
  }
  return '';
}
