// validationsMixin
//
// Provides a validation error propagation framework for use by UI components.
// Can be included at the lowest level element (k-select) or at a higher level
// wrapper (page level).
//
// Usage:
//
// Low level components
// * include this mixin directly: `mixins: [validationsMixin],`
// * add ref=input to low level <input> element
//
// Wrappers (high level components)
// * include this mixin directly: `mixins: [validationsMixin.events],`
// * implement `isValid` computed prop

import { Errors } from 'adready-api/model/errors';
import { ValidationError } from 'adready-api/model/validation-error';

const props = {
  required: {
    required: false,
    type: Boolean,
    default: false,
  },
  valid: {
    required: false,
    type: Boolean,
    default: null,
  },
};

// Used by the events-only export
function dataEvents() {
  return {
    errors: new Errors(),
    deferredErrors: new Errors(),
  };
}

// Used by default export (for low-level components)
function data() {
  return {
    errors: new Errors(),
    deferredErrors: new Errors(),
    input: null, // reference to upload input element
  };
}

const computed = {
  // whether or not to show the required label
  showRequired() {
    return this.valid !== true && this.isRequired;
  },

  haveErrors() {
    // No errors required if External MNI account.
    if (this.isExternalMni) {
      return false;
    }
    return this.errors && this.errors.length > 0;
  },

  // Default errorTooltip implementation for displaying errors back to the user.
  // Flattens the local Errors object and concats errors one per line.
  errorTooltip() {
    return this.errors
      .flatten()
      .map((e) => (e.message ? e.message : e.toString()))
      .join('; ');
  },
};

// Since $refs are non-reactive, we update `this.input` in mounted/updated
// lifecycle events.
function mounted() {
  this.input = this.$refs.input;
}

function updated() {
  if (this.input !== this.$refs.input) {
    this.input = this.$refs.input;
    this.$nextTick(() => {
      this.onValidation();
    });
  }
}

const methods = {
  // Shortcut to update errors and propagate an event
  updateError(key, val) {
    this.errors.update(key, val);
    this.$emit('validation', this.errors);
  },

  // Default onValidation handler for processing and propagating errors. Can be
  // used to handle validation of low level components (inputs) as well as
  // events emitted by other Vue components (usually by this method itself).
  onValidation(errors, key, deferErrors) {
    // figure out what type of input we have
    let v = errors;
    if (!v && this.input && this.input.validity) {
      // errors not passed and we have an input el
      // it's an HTMLElement, record constraint validation errors
      v = this.input.validity.valid
        ? null // no errors
        : new ValidationError(this.input);
    }

    if (deferErrors === true || this.deferErrors === true) {
      this.deferredErrors.update(key, v);
      return;
    }

    // update and propagate to parent
    this.errors.update(key, v);
    this.$emit('validation', this.errors);
  },
};

// used by high-level components
const events = {
  data: dataEvents,
  computed,
  methods,
};

// used by low-level components
export default {
  props,
  data,
  computed,
  mounted,
  updated,
  methods,
  events,
};
