export default {
  props: {
    name: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    checked: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      type: [String, Number],
      default: null,
    },
    checkedIcon: {
      required: false,
      type: String,
      default: 'check-square',
    },
    uncheckedIcon: {
      required: false,
      type: String,
      default: 'square',
    },
  },

  data() {
    return {
      internalValue: this.checked,
    };
  },

  computed: {
    isChecked() {
      return this.internalValue;
    },
  },

  watch: {
    checked(val) {
      this.internalValue = val;
    },
  },

  methods: {
    onClick() {
      this.internalValue = !this.internalValue;
      const v = { checked: this.internalValue, name: this.name, value: this.value };
      this.$emit('selected', v);
      this.$emit('change', v);
      this.$emit('input', v);
    },
  },
};
