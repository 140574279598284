export const isPopupBlocked = () => {
  const myPopup = window.open(
    window.location.href,
    '',
    'directories=no,height=150,width=150,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,top=0,location=no'
  );
  if (!myPopup) {
    return true;
  }
  myPopup.onload = () => {
    setTimeout(() => {
      if (myPopup.screenX === 0) {
        return true;
      }
      myPopup.close();
      return false;
    }, 0);
  };
  myPopup.close();
  return false;
};
