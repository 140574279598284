<template>
  <div class="checkbox-btn row middle-xs" @click="onClick">
    <font-awesome-icon
      v-if="isChecked"
      :icon="['fas', checkedIcon]"
      class="check-on checkbox-active"
    />
    <font-awesome-icon
      v-if="!isChecked"
      :icon="['fas', uncheckedIcon]"
      class="check-off checkbox-inactive"
    />
    <span v-if="label">{{ label }}</span>
  </div>
</template>
<script>
import KCheckboxMixin from '../mixins/k-checkbox-mixin';

export default {
  name: 'KCheckbox',
  mixins: [KCheckboxMixin],
};
</script>

<style lang="scss" scoped>
.checkbox-btn {
  margin: 0;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 20px;
    &.checkbox-active {
      color: $dr_blue;
    }
    &.checkbox-inactive {
      color: #999;
    }
  }

  span {
    margin: 0 10px;
    font-size: 0.8em;
  }
}

.product-type-slider .checkbox-btn span {
  margin: 0 0 0 5px;
  font-size: inherit;
}
</style>
