import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faCaretUp,
  faChartPie,
  faChartBar,
  faCheck,
  faCheckCircle,
  faMinusCircle,
  faCheckSquare,
  faCircle,
  faClone,
  faCloudDownload,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCog,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileExport,
  faInfoCircle,
  faInfo,
  faLightbulbOn,
  faPaperPlane,
  faPlane,
  faPen,
  faPencil,
  faShoePrints,
  faSquare,
  faTrash,
  faWrench,
  faWindowClose,
  faShare,
  faShareSquare,
  faSave,
  faLink,
  faSort,
  faStar,
  faCode,
  faTimes,
  faTachometer,
  faBookOpen,
  faChartLine,
  faPause,
  faRocket,
  faPlus,
  faPlay,
  faArrowToBottom,
  faMinus,
  faCopy,
  faUserFriends,
  faTimesSquare,
  faExclamation,
  faExclamationCircle,
  faExternalLink,
  faLayerGroup,
  faThumbsUp,
  faGlobe,
  faChartNetwork,
  faFolderTree,
  faChartLineUp,
  faBillboard,
  faRectangleAd,
  faUsers,
  faCheckDouble,
  faArrowUpRight,
  faEllipsis,
  faArrowDownToLine,
  faMapMarkerAlt,
  faFont,
  faCircleCheck,
  faPenRuler,
  faChartUser,
  faFolderOpen,
  faFolderHeart,
  faFolderClosed,
  faHouseBuilding,
} from '@fortawesome/pro-solid-svg-icons';

// configure
library.add(
  faAngleDown,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faAngleRight,
  faChartPie,
  faChartBar,
  faCheck,
  faCheckCircle,
  faMinusCircle,
  faCheckSquare,
  faCircle,
  faClone,
  faCloudDownload,
  faChevronDown,
  faChevronRight,
  faCog,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileExport,
  faLightbulbOn,
  faPaperPlane,
  faPlane,
  faPen,
  faPencil,
  faShoePrints,
  faSquare,
  faTrash,
  faWindowClose,
  faSave,
  faShare,
  faShareSquare,
  faWrench,
  faLink,
  faSort,
  faInfoCircle,
  faInfo,
  faStar,
  faCode,
  faTimes,
  faTachometer,
  faBookOpen,
  faChartLine,
  faRocket,
  faPlus,
  faPause,
  faPlay,
  faArrowToBottom,
  faMinus,
  faCopy,
  faUserFriends,
  faTimesSquare,
  faExclamation,
  faExclamationCircle,
  faExternalLink,
  faLayerGroup,
  faThumbsUp,
  faGlobe,
  faChartNetwork,
  faFolderTree,
  faChartLineUp,
  faBillboard,
  faRectangleAd,
  faUsers,
  faCheckDouble,
  faArrowUpRight,
  faEllipsis,
  faArrowDownToLine,
  faMapMarkerAlt,
  faFont,
  faCircleCheck,
  faPenRuler,
  faChartUser,
  faFolderOpen,
  faFolderHeart,
  faFolderClosed,
  faHouseBuilding
);
